import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import TechHeader from "./TechHeader";
import "rsuite/dist/rsuite.min.css";
import "../../scss/tech.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AutoComplete, Table, Pagination } from "rsuite";

export default function TechList(prop) {
  let lang = prop.lang ? prop.lang : "kr";
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  let q_cate = query.get("cate");
  q_cate = q_cate ? q_cate.split(",") : [];
  let q_search = query.get("search");
  q_search = q_search ? q_search : "";

  let [category, setCategory] = useState(q_cate);
  let [title, setTitle] = useState(["0x01", "0x10"]);
  let [list, setList] = useState([]);
  const { Column, HeaderCell, Cell } = Table;

  let [org_list, setOrgList] = useState([]);

  let [searchCategory, setSearchCategory] = useState(q_cate);
  let [searchWord, setSearchWord] = useState(q_search);

  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (list.length) {
      filterList(searchWord, searchCategory);
      navigate(
        {
          pathname: window.location.pathname,
          search: "?cate=" + searchCategory.join(",") + "&search=" + searchWord,
        },
        { replace: true }
      );
    }
  }, [searchWord, searchCategory, org_list]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelectorAll(".header_wrap").forEach(function (element) {
      element.style.display = "none";
    });
    loadData();
  }, [lang]);

  useEffect(() => {
    var btn = document.querySelector(".btn-search");

    var click = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document
      .getElementsByClassName("rs-input")[0]
      .addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          //filterList(event.target.value, searchCategory);
          btn.dispatchEvent(click);
        }
      });

    // 클릭 이벤트 트리거
  }, [org_list]);
  const data = list.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  function loadData(id) {
    //let _url = APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/1/'
    let _url = "/resources/tech/" + lang + "/list.json";

    axios
      .get(_url, {
        //
      })
      .then(function (response) {
        const res = response.data.result;

        let _cate = [];
        for (let i = 0; i < res.length; i++) {
          for (let j = 0; j < res[i].category.length; j++) {
            if (_cate.indexOf(res[i].category[j]) < 0) {
              _cate.push(res[i].category[j]);
            }
          }
        }
        let _title = [];
        for (let i = 0; i < res.length; i++) {
          res[i].res_title = res[i].errorcode
            ? res[i].title + "@#$" + res[i].errorcode + ""
            : res[i].title;
          _title.push(res[i].res_title);
        }
        const orgList = JSON.parse(JSON.stringify(res));
        setOrgList(orgList);
        //setOrgList(JSON.parse(JSON.stringify(res)));
        setTitle(_title);
        setCategory(_cate);
        setList(res);
      });
  }
  function onChangeCategory(e) {
    const selectedEls = document.querySelectorAll(
      'input[name="category"]:checked'
    );
    let result = [];
    selectedEls.forEach((el) => {
      result.push(el.value);
    });
    setSearchCategory(result);
    filterList(searchWord, result);
  }
  function filterList(value, $category = []) {
    if ($category.length === 0) {
      $category = category;
    }
    const searchString = value.toUpperCase();
    const filteredData = org_list.filter(
      (item) =>
        item.res_title.toUpperCase().includes(searchString) ||
        item.model.toUpperCase().includes(searchString)
    );
    console.log("filterList", filteredData, value);
    //let _list = [];
    //const categoryItems = data.filter(item => item.category.includes("Program"));

    /*filteredData.forEach((item) => {
      if (item.category.some((value) => $category.includes(value))) {
        _list.push(item);
      }
    });*/
    const _list = filteredData.filter((item) =>
      item.category.some((value) => $category.includes(value))
    );
    console.log("1111", _list, $category);
    //
    setPage(1);
    setList(_list);
  }
  const LinkCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
      <Link to={`/${lang}/tech/view/${rowData.id}`}>{rowData[dataKey]}</Link>
    </Cell>
  );
  const AutoItem = (item) => {
    //let _item = item.split("@#$")[0] + "... " + item.split("@#$")[1];
    //console.log(item.props.children);
    let it = item.props.children.split("@#$")[0];
    return <div>{it}</div>;
  };
  return (
    <>
      <div id="container">
        <TechHeader lang={lang} />
        <div id="contents" className="tech_solution">
          <div className="tech_inner">
            <p className="tech_title">Technical Solution</p>
            {/* <div className="tech_manual">
                            <p className="sub_tit">Accessing the MOPIC Mirror Manual</p>
                            <div className="manual_wrap">
                                <p className="sub_desc">For more detailed information, please refer to the MOPIC Mirror manual:</p>
                                <ul className="manual_list">
                                    <li>
                                        <a href="./">[EN] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a>
                                    </li>
                                    <li>
                                        <a href="./">[KR] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
            <div className="tech_search">
              <AutoComplete
                data={title}
                style={{ width: "100%" }}
                defaultValue={searchWord}
                onSelect={(value) => {
                  filterList(value, searchCategory);
                }}
                renderMenuItem={(item) => {
                  return <div>{AutoItem(item)}</div>;
                }}
                onChange={(e) => {
                  setSearchWord(e);
                }}
                placeholder="Enter the symptoms or error code."
              />
              {/* <input type="text" placeholder="Enter the symptoms or error code." /> */}
              <button
                type="button"
                className="btn-search"
                onClick={() => {
                  filterList(searchWord, searchCategory);
                }}>
                <span className="blind">Search</span>
              </button>
            </div>
            <div className="tech_category">
              {lang == "en" ? (
                <p className="sub_tit">What's the issue?</p>
              ) : (
                <p className="sub_tit">어떤 문제가 발생했나요?</p>
              )}
              <div className="cate_wrap">
                {category.map((row, idx) => (
                  <span className="check-wrap" key={"key_category" + idx}>
                    <input
                      type="checkbox"
                      name="category"
                      value={row}
                      id={"cate" + idx}
                      onChange={onChangeCategory}
                      checked={searchCategory.indexOf(row) > -1}
                    />
                    <label htmlFor={"cate" + idx}>
                      <span>{row}</span>
                    </label>
                  </span>
                ))}
              </div>
            </div>
            <div className="tech_list">
              <div className="list_title_wrap">
                {lang == "en" ? (
                  <p className="sub_tit">Result</p>
                ) : (
                  <p className="sub_tit">결과</p>
                )}
                {/* <p className="list_count">Total <span>{list.length}</span></p> */}
              </div>

              <Table autoHeight headerHeight={46} data={data}>
                <Column flexGrow={3}>
                  <HeaderCell
                    style={{
                      fontSize: "16px",
                      padding: "10px",
                      color: "#000",
                    }}>
                    Model
                  </HeaderCell>
                  <Cell dataKey="model" />
                </Column>

                <Column flexGrow={14}>
                  <HeaderCell
                    style={{
                      fontSize: "16px",
                      padding: "10px",
                      color: "#000",
                    }}>
                    Title
                  </HeaderCell>
                  <LinkCell dataKey="title" />
                </Column>

                <Column flexGrow={2}>
                  <HeaderCell
                    style={{
                      fontSize: "16px",
                      padding: "10px",
                      color: "#000",
                    }}>
                    Date
                  </HeaderCell>
                  <Cell dataKey="date" />
                </Column>
              </Table>

              <div style={{ padding: 20 }}>
                <Pagination
                  prev
                  next
                  first
                  last
                  maxButtons={5}
                  size="lg"
                  layout={["total", "-", "pager"]}
                  total={list.length}
                  limitOptions={[]}
                  limit={limit}
                  activePage={page}
                  onChangePage={setPage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>

              {/* <table className="list_cont_wrap">
                                <colgroup>
                                    <col width="15%" />
                                    <col width="*" />
                                    <col width="15%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className='model'>Model</th>
                                        <th className='tit'>Title</th>
                                        <th className='date'>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {list.map((row, idx) => (
                                    <tr key={"list"+idx}>
                                        <td className='model'>{row.model}</td>
                                        <td className='tit'><Link to={"/en/tech/view/"+row.id}>{row.res_title}</Link></td>
                                        <td className='date'>{row.date}</td>
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                    <Page ></Page>
                                </tfoot>
                            </table> */}
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} className="mt0"></Footer>
    </>
  );
}
